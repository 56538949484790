import HTTP from "@ui/Utils/http";
import commonConfig from "config/commonConfig";

const baseApiUrl = commonConfig.baseApiUrl;

export const syncRecordsToDiva = async () => {
  try {
    const url = baseApiUrl + `c/data-engine/products/syncRecordsToDiva`;

    const res = await HTTP.post(url);
    return res?.data?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};
