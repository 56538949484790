import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import CustomEventEmitter from "@ui/Utils/CustomEventEmitter";
import {
  createRecord,
  getByCode,
  updateRecord,
} from "@ui/ComponentUtils/blueprintAPIs";

import enums from "helpers/enums";
import DetailHeader from "./components/DetailHeader";

const validationSchema = yup.object().shape({
  name: yup
    .string()
    .required("Required")
    .test(
      "is-not-empty",
      "Cannot be empty",
      (value) => value && value.trim().length > 0
    )
    .matches(/^[A-Za-z0-9\s]+$/, "Special characters are not allowed"),
});

const entitySchema = {
  name: "",
};

const SubCategoryDetail = () => {
  const formMethods = useForm({
    defaultValues: entitySchema,
    mode: "all",
    resolver: yupResolver(validationSchema),
  });
  const navigate = useNavigate();

  const params = useParams();
  const model = enums.models.subCategory;
  const [isEditable, setIsEditable] = useState(false);

  useEffect(() => {
    (async () => {
      const id = params?.code;

      try {
        if (id !== "create") {
          const res = await getByCode(model, id);

          formMethods.reset(res);
          setIsEditable(false);
        } else {
          setIsEditable(true);
        }
      } catch (err) {
        navigate("/" + enums.models.masterData);
        console.log(err);
      }
    })();
  }, [params?.code]);

  const onSubmit = async (values, metaData) => {
    try {
      let res = {},
        action = "Created";

      if (!values.code) {
        if (
          window.confirm(
            `SubCategory Name: "${values.name}" cannot be changed once created. \nAre you sure to create?`
          )
        ) {
          res = await createRecord(model, values);
          navigate(`/${model}/${res?.code}`);
        } else return;
      } else {
        res = await updateRecord(model, values);
        action = "Updated";
      }
      setIsEditable(false);
      formMethods.reset(res);
      CustomEventEmitter.emit("alert_success", "Successfully " + action);
    } catch (err) {
      console.log(err);
      CustomEventEmitter.emit("alert_error", err?.response?.data?.message);
    }
  };

  return (
    <FormProvider {...formMethods}>
      <DetailHeader
        isEditable={isEditable}
        onSubmit={onSubmit}
        setIsEditable={setIsEditable}
      />

      {/* <DetailContent isEditable={isEditable} /> */}
    </FormProvider>
  );
};

export default SubCategoryDetail;
