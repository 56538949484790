import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import CustomEventEmitter from "@ui/Utils/CustomEventEmitter";
import { createRecord, getByCode } from "@ui/ComponentUtils/blueprintAPIs";

import enums from "helpers/enums";
import DetailHeader from "./components/DetailHeader";

const validationSchema = yup.object().shape({
  value: yup
    .number()
    .typeError("Must be a valid number")
    .required("Required *"),
  percent: yup
    .number()
    .typeError("Must be a valid number")
    .min(1, "Must be between 1 - 100")
    .max(100, "Must be between 1 - 100")
    .test("is-decimal", "2 decimals max", (value) => {
      if (value === undefined || value === null) return true; // Skip validation if value is empty
      return /^-?\d+(\.\d{1,2})?$/.test(value);
    })
    .required("Required *"),
});

const entitySchema = {};

const PurityDetail = () => {
  const formMethods = useForm({
    defaultValues: entitySchema,
    mode: "all",
    resolver: yupResolver(validationSchema),
  });
  const navigate = useNavigate();

  const params = useParams();
  const model = enums.models.purity;
  const [isEditable, setIsEditable] = useState(false);

  useEffect(() => {
    (async () => {
      const id = params?.code;

      try {
        if (id !== "create") {
          const res = await getByCode(model, id);

          res.percent *= 100;
          formMethods.reset(res);
          setIsEditable(false);
        } else {
          setIsEditable(true);
        }
      } catch (err) {
        navigate("/" + enums.models.masterData);
        console.log(err);
      }
    })();
  }, [params?.code]);

  const onSubmit = async (values, metaData) => {
    try {
      let res = {},
        action = "Created";
      if (
        !values.code &&
        window.confirm(
          `Purity once created cannot be modified. \nAre you sure to create ${
            values.value
          } KT with ${Number(values.percent.toFixed(2))}% purity?`
        )
      ) {
        values.percent = values.percent / 100;
        res = await createRecord(model, values);
        navigate(`/${model}/${res?.code}`);
        setIsEditable(false);
        formMethods.reset({ ...res, percent: res.percent * 100 });
        CustomEventEmitter.emit("alert_success", "Successfully " + action);
      }
      // else {
      //   res = await updateRecord(model, values);
      //   action = "Updated";
      // }
    } catch (err) {
      console.log(err);
      CustomEventEmitter.emit(
        "alert_error",
        err?.response?.data?.message?.toLowerCase().match("duplicate")
          ? `Purity with ${values.value} KT or ${values.percent * 100} % exists`
          : "Something went wrong"
      );
    }
  };

  return (
    <FormProvider {...formMethods}>
      <DetailHeader
        isEditable={isEditable}
        onSubmit={onSubmit}
        setIsEditable={setIsEditable}
      />
    </FormProvider>
  );
};

export default PurityDetail;
