import GeneralSection from "@ui/ComponentUtils/GeneralSection";
import Header from "@ui/ComponentUtils/Header";
import React, { useEffect, useState } from "react";

import { createFilterOptions } from "@mui/material/Autocomplete";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useFormContext } from "react-hook-form";
import enums from "helpers/enums";
import { Grid } from "@material-ui/core/index";
import PercentConverter from "@ui/Actions/Percentage/PercentageConverter";

const filter = createFilterOptions();

const DetailHeader = (props) => {
  const { isEditable, onSubmit, setIsEditable } = props;
  const formMethods = useFormContext();

  const values = formMethods.watch();

  const params = useParams();
  const navigate = useNavigate();

  const handleCancel = () => {
    if (window.confirm("Are you sure to cancel? Changes will be discarded.")) {
      if (params?.code === "create") {
        navigate("/" + enums.models.masterData);
      } else {
        formMethods.reset();
        setIsEditable(false);
      }
    }
  };

  const entityFields = [
    {
      name: "value",
      label: "Value (KT)",
      fieldType: enums.fieldTypes.textField,
      readOnly: values.code,
      dataType: enums.dataTypes.number,
    },

    {
      name: "percent",
      label: "Percent ( 1 - 100 )",
      fieldType: enums.fieldTypes.textField,
      dataType: enums.dataTypes.number,
      render: (vals) =>
        isNaN(parseFloat(vals?.percent))
          ? ""
          : Number(vals?.percent)?.toFixed(2),
    },
  ];

  const links = [
    {
      name: "Master Data",
      url: "#/" + enums.models.masterData,
      isDisplayLink: true,
    },
  ];

  const actions = [
    // Purity should not be edited, because its not a reference and other products might already have the purity that is getting edited. So, it should be a constant per division
    {
      label: "Cancel",
      iconName: "cancel",
      hidden: !isEditable,
      onClick: handleCancel,
    },
    {
      label: "Save",
      iconName: "save",
      hidden: !isEditable,
      type: "submit",
      onClick: formMethods.handleSubmit(onSubmit),
    },
    // {
    //   label: "Edit",
    //   iconName: "edit",
    //   hidden: isEditable,
    //   onClick: () => {
    //     setIsEditable(true);
    //   },
    // },
  ];

  return (
    <>
      <Header links={links} pageTitle={"Purity"} actions={actions} />

      <GeneralSection
        // actions={sectionActions}
        fields={entityFields}
        title="Information"
        isEditable={isEditable}
        values={values}
      />
    </>
  );
};

export default DetailHeader;
