import { advanceDesignFilter } from "@ui/ComponentUtils/blueprintAPIs";
import FilterByFields, {
  getFieldFilters,
} from "@ui/Controls/Search/FilterByFields";

import enums from "helpers/enums";
import React, { useEffect, useState } from "react";
import Classifications from "./Classifications";
import Emitter from "@ui/Utils/CustomEventEmitter";
import _ from "lodash";

const INFERENCE_IMAGE_SESSION = enums.models.products + "/inferenceImage";

export const filterFields = [
  {
    name: "styleNo",
    label: "Style No",
    fieldType: enums.fieldTypes.textField,
  },
  { name: "sku", label: "SKU", fieldType: enums.fieldTypes.textField },
  {
    name: "stockStatus",
    label: "Stock Status",
    fieldType: enums.fieldTypes.dropdown,
    optios: Object.values(enums.stockStatus),
  },
  {
    name: "category.name",
    label: "Category",
    fieldType: enums.fieldTypes.asyncDropdown,
    apiUrl: enums.models.category + "/search",
    apiMethod: "post",
  },
  {
    name: "subCategory.name",
    label: "Sub Cat.",
    fieldType: enums.fieldTypes.asyncDropdown,
    apiUrl: enums.models.subCategory + "/search",
    apiMethod: "post",
  },
];

export const defaultPageSize = 32;

// used sessionStorage keys are,
// 1. data-engine/products/inventorySearch
// 2. data-engine/products/inventoryPagination

export const searchSessionName = enums.models.products + "/inventorySearch";
const stockWiseSessionName = enums.models.products + "/stockWise";

const InventoryList = () => {
  const [classification, setClassification] = useState([]);
  const [pagination, setPagination] = useState({
    page: 0,
    pageSize: defaultPageSize,
  });
  const [metaInfo, setMetaInfo] = useState({});
  const [mongooseQuery, setMongooseQuery] = useState({});
  const [defaultCondition, setDefaultCondition] = useState({});

  const [stockWise, setStockWise] = useState(true);

  useEffect(() => {
    try {
      let searchFilter = window.sessionStorage.getItem(searchSessionName);
      searchFilter = searchFilter ? JSON.parse(searchFilter) : { styleNo: [] };
      setDefaultCondition(searchFilter);
      const criteria = getFieldFilters(searchFilter);

      // const localImage = window.sessionStorage.getItem(INFERENCE_IMAGE_SESSION);

      fetchStocks(criteria);
    } catch (err) {
      console.log(err);
    }
  }, []);

  useEffect(() => {
    let stockWiseFilter = window.sessionStorage.getItem(stockWiseSessionName);
    if (stockWiseFilter) {
      setStockWise(JSON.parse(stockWiseFilter));
    }
  }, []);

  useEffect(() => {
    if (typeof stockWise === "boolean") {
      window.sessionStorage.setItem(
        stockWiseSessionName,
        JSON.stringify(stockWise)
      );
    }
  }, [stockWise]);

  const fetchStocks = async (searchFormData) => {
    try {
      let invPagination = window.sessionStorage.getItem(
        enums.models.products + "/inventoryPagination"
      );

      invPagination = invPagination
        ? JSON.parse(invPagination)
        : { page: 0, pageSize: defaultPageSize };
      const form = new FormData();

      if (searchFormData?.inferenceFormData?.name) {
        form.append("images", searchFormData.inferenceFormData);
        delete searchFormData.inferenceFormData;
      } else {
        form.append("pageNo", invPagination.page + 1);
        form.append("perPageRecords", invPagination.pageSize);
        // form.append("sort", invPagination.sort );
      }

      if (searchFormData) {
        form.append("filter", JSON.stringify(searchFormData));
      }

      let stockWiseFilter = window.sessionStorage.getItem(stockWiseSessionName);
      if (stockWiseFilter) {
        stockWiseFilter = JSON.parse(stockWiseFilter);
      }

      form.append("stockWise", stockWiseFilter);

      const res = await advanceDesignFilter(
        enums.models.products,
        form,
        "priceAfterDiscount=true"
      );

      setPagination(invPagination);
      setMongooseQuery(searchFormData);

      setClassification(res.classification);
      setMetaInfo(res.paginationInfo || {});
    } catch (err) {
      console.log(err);
      Emitter.emit("alert_error", "Something went wrong");
    }
  };

  const handleFieldLevelSearch = (searchFormData) => {
    window.sessionStorage.setItem(
      enums.models.products + "/inventoryPagination",
      JSON.stringify({ page: 0, pageSize: defaultPageSize })
    );
    fetchStocks(searchFormData);

    setPagination({ page: 0, pageSize: defaultPageSize });
  };

  return (
    <div>
      <FilterByFields
        metaInfo={metaInfo}
        handleFieldLevelSearch={handleFieldLevelSearch}
        defaultCondition={defaultCondition}
        searchSessionName={searchSessionName}
        stockWise={stockWise}
        setStockWise={setStockWise}
      />

      <div style={{ marginTop: "48px" }}>
        <Classifications
          fetchStocks={fetchStocks}
          pagination={pagination}
          setPagination={setPagination}
          classification={classification}
          metaInfo={metaInfo}
          mongooseQuery={mongooseQuery}
        />
      </div>
    </div>
  );
};

export default InventoryList;
