import React from "react";
import enums from "helpers/enums";
import GeneralListPage from "@ui/ComponentUtils/GeneralListPage";
import { formatDateToRead } from "@ui/Utils/helper";

const columns = [
  {
    title: "Value",
    field: "value",
    render: (row) => row?.value + " KT",
  },
  {
    title: "Percent",
    field: "percent",
    render: (row) => (row?.percent * 100).toFixed(2) + " %",
  },

  {
    title: "Created Date",
    field: "dateCreated",
    render: (row) => formatDateToRead(row?.dateCreated),
  },
  {
    title: "Created By",
    render: (row) => row?.createdBy?.firstName + " " + row?.createdBy?.lastName,
  },
];

const PuritiesList = () => {
  return (
    <GeneralListPage
      columns={columns}
      model={enums.models.purity}
      headerTitle="Purities"
      searchFields={["name"]}
      paginationMeta={{ pageSize: 5 }}
      hideAction={{ delete: true, selection: true }}
      onRowClick={false}
    />
  );
};

export default PuritiesList;
