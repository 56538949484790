import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  IconButton,
} from "@material-ui/core";
import { MuiDialogActions } from "@ui/assets/materialUI/MaterialUIComponents";
import { makeStyles } from "@material-ui/core/styles";
import Label from "@ui/components/Label";
import CustomEventEmitter from "@ui/Utils/CustomEventEmitter";

const useStyles = makeStyles((theme) => ({
  coolButton: {
    background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
    border: 0,
    borderRadius: 3,
    boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
    color: "white",
    height: 48,
    padding: "0 30px",
    "&:hover": {
      background: "linear-gradient(45deg, #FF8E53 30%, #FE6B8B 90%)",
      transition: "background 0.5s ease-in-out",
    },
  },
}));

const PercentConverter = ({
  decimalPercent,
  onChange = () => {},
  isEditable,
  totalPrice = 0,
  priceToPercent = false,
  hideConverter = false,
}) => {
  const [open, setOpen] = useState(false);
  const [fullPercent, setFullPercent] = useState("");
  const [fieldError, setFieldError] = useState("");
  const classes = useStyles();

  useEffect(() => {
    let tempFullPercent = (decimalPercent || 0) * 100;
    if (priceToPercent) {
      tempFullPercent = ((decimalPercent || 0) / 100) * totalPrice * 100;
    }
    if (tempFullPercent)
      if (!priceToPercent) {
        tempFullPercent = tempFullPercent.toFixed(1);
      }
    setFullPercent(Number(tempFullPercent));
  }, [decimalPercent, totalPrice, open]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    if (priceToPercent && !totalPrice) {
      return CustomEventEmitter.emit("alert_error", "Price required");
    } else setOpen(true);
  };

  const handleConvert = () => {
    let convertedPercent = fullPercent / 100;
    let invalidFlag = false;

    if (priceToPercent) {
      if (Number(fullPercent) > totalPrice) {
        invalidFlag = true;
        setFieldError("Must be less than Max Price");
      }

      convertedPercent = (((fullPercent || 0) / totalPrice) * 100) / 100;
    } else if (Number(fullPercent) > 100 || Number(fullPercent) < 0) {
      invalidFlag = true;

      setFieldError("Number must be between 0 - 100");
    }

    if (!invalidFlag) {
      onChange(
        Number(priceToPercent ? convertedPercent : convertedPercent.toFixed(3))
      );
      handleClose();
    }
  };

  return (
    <>
      <Label
        label={priceToPercent ? "Price Discount" : "Discount %"}
        value={fullPercent + (priceToPercent ? "" : fullPercent ? " %" : "")}
      />
      {hideConverter ? null : (
        <Button
          className={classes.coolButton}
          onClick={handleOpen}
          disabled={!isEditable}
        >
          Open Converter
        </Button>
      )}

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          {priceToPercent
            ? `Enter Price: less than ${totalPrice}`
            : "Enter Percentage: 1 - 100"}
        </DialogTitle>
        <DialogContent>
          <FullPercentInput
            fullPercent={fullPercent}
            setFullPercent={setFullPercent}
            priceToPercent={priceToPercent}
            fieldError={fieldError}
            setFieldError={setFieldError}
          />
        </DialogContent>
        <MuiDialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleConvert} color="primary">
            Convert
          </Button>
        </MuiDialogActions>
      </Dialog>
    </>
  );
};

function FullPercentInput({
  fullPercent,
  setFullPercent,
  priceToPercent,
  fieldError,
  setFieldError,
}) {
  const handleChange = (e) => {
    const value = e.target.value;

    // Allow empty input to handle delete and reset scenarios
    if (value === "") {
      setFullPercent("");
      setFieldError("");
      return;
    }

    // Regex to match a number with up to one decimal place
    const regex = priceToPercent ? /^\d*\.?\d*$/ : /^\d*\.?\d{0,1}$/;

    if (regex.test(value)) {
      setFullPercent(value);
      setFieldError("");
    } else {
      setFieldError("1" + " decimal max");
    }
  };

  return (
    <TextField
      type="number"
      onChange={handleChange}
      value={fullPercent || ""}
      size="small"
      variant="outlined"
      fullWidth
      label={priceToPercent ? `Price` : "Percent"}
      helperText={fieldError}
      error={Boolean(fieldError)}
    />
  );
}

export default PercentConverter;
