// Common File Used in this and Category screen for Diamond and Gold Discounts
import React, { Fragment, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import AddIcon from "@mui/icons-material/Add";
import Tooltip from "@mui/material/Tooltip";
import { Grid } from "@mui/material";
import RHTextField from "@ui/ReactHookFormControls/RHTextField";
import { useFieldArray } from "react-hook-form";
import Label from "@ui/components/Label";
import RHAutoComplete from "@ui/ReactHookFormControls/RHAutoComplete";
import DeleteIcon from "@mui/icons-material/Delete";
import { getNestedObjValue } from "@ui/Utils/helper";
import Emitter from "@ui/Utils/CustomEventEmitter";

const DiscountGroup = ({
  isEditable,
  customers = [],
  discountGroupField = "discountGroup",
  totalPrice,
  isDiamondPricingFlag = false,
}) => {
  const formMethods = useFormContext();
  const { control, watch } = formMethods;
  const values = watch();
  const rerenderedFields = getNestedObjValue(values, discountGroupField);

  const { fields, append, remove, update } = useFieldArray({
    control,
    name: discountGroupField,
  });

  return (
    <Grid container spacing={2}>
      <Grid item xs={11} container spacing={2}>
        {fields.map((field, fieldIndex) => (
          <DiscountField
            key={field.id}
            fields={fields}
            field={field}
            fieldIndex={fieldIndex}
            isEditable={isEditable}
            customers={customers}
            discountGroupField={discountGroupField}
            remove={remove}
            update={update}
            totalPrice={totalPrice}
            values={values}
            isDiamondPricingFlag={isDiamondPricingFlag}
          />
        ))}
      </Grid>

      <Grid item xs={1} style={{ textAlign: "center" }}>
        {isEditable ? (
          <Tooltip title="Add Discount Group">
            <AddIcon
              style={{ cursor: "pointer" }}
              onClick={() => {
                if (
                  (rerenderedFields || []).find((row) => !row.users?.length)
                ) {
                  Emitter.emit(
                    "alert_error",
                    `Fill # ${rerenderedFields?.length} to continue`
                  );
                } else {
                  append({ customPrice: "", users: [] });
                }
              }}
            />
          </Tooltip>
        ) : null}
      </Grid>
    </Grid>
  );
};

const DiscountField = ({
  field,
  fields,
  fieldIndex,
  isEditable,
  customers,
  discountGroupField,
  remove,
  isDiamondPricingFlag,
}) => {
  const { watch } = useFormContext();
  const currentDiscountGroup = watch(discountGroupField);

  return (
    <Fragment>
      <Grid item xs={1}>
        <pre># {fieldIndex + 1}</pre>
      </Grid>
      <Grid item xs={4}>
        {isEditable ? (
          <RHAutoComplete
            getOptionLabel={(option) =>
              `${option?.firstName || ""} ${option?.lastName || ""} (${
                option?.phone || ""
              })`
            }
            getOptionSelected={(option, value) =>
              `${option.firstName + option.lastName} (${option?.phone})` ===
              `${value.firstName + option.lastName} (${value?.phone})`
            }
            name={`${discountGroupField}[${fieldIndex}].users`}
            label="Customers"
            multiple
            options={customers.filter(
              (customer) =>
                !(currentDiscountGroup || []).find((group, groupIndex) =>
                  (group.users || []).find(
                    (user) =>
                      (user?._id || user) === customer._id &&
                      groupIndex !== fieldIndex
                  )
                )
            )}
          />
        ) : (
          <Label
            label={"Customers"}
            value={(field.users || [])
              .map(
                (user) =>
                  `${user.firstName + " " + user.lastName} (${
                    user.phone || ""
                  })`
              )
              .join(", ")}
          />
        )}
      </Grid>

      <Grid item xs={3}>
        {isEditable ? (
          <RHTextField
            type="number"
            name={`${discountGroupField}[${fieldIndex}].customPrice`}
            label="Custom Price"
          />
        ) : (
          <Label label={"Custom Price"} value={field.customPrice} />
        )}
      </Grid>

      <Grid item xs={3}>
        {!isDiamondPricingFlag &&
          (isEditable ? (
            <RHTextField
              type="number"
              name={`${discountGroupField}[${fieldIndex}].customWastage`}
              label="Custom Wastage %"
            />
          ) : (
            <Label label={"Custom Wastage %"} value={field.customWastage} />
          ))}
      </Grid>

      <Grid item xs={1}>
        {isEditable ? (
          <Tooltip title="Delete Discount">
            <DeleteIcon
              style={{ cursor: "pointer" }}
              onClick={() => remove(fieldIndex)}
            />
          </Tooltip>
        ) : null}
      </Grid>
    </Fragment>
  );
};

export default DiscountGroup;
