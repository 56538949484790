import React, { useEffect, useState } from "react";
import CategoryList from "views/Category/List/List";
import { Grid } from "@mui/material";
import CollectionLineList from "views/Collection Lines/List/List";
import DiamondPricingList from "views/DiamondPricing/List/List";
import SubCategoryList from "views/SubCategory/List/List";
import VendorCodesList from "@ui/views/vendor-portal/VendorCodes/List/List";
import PuritiesList from "views/Purity/List/List";
import GoldPricingList from "views/GoldPricing/List/List";
import { getSingleRecord } from "@ui/ComponentUtils/blueprintAPIs";
import enums from "helpers/enums";
import LazyLoad from "@ui/Actions/LazyLoad/index";

const MasterData = () => {
  const [configuration, setConfiguration] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        setConfiguration(await getSingleRecord(enums.models.configuration));
        setLoading(false);
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  return (
    <Grid spacing={3} container>
      {configuration?.diamondFields ? (
        <Grid item xs={12}>
          <DiamondPricingList />
        </Grid>
      ) : null}

      <Grid item xs={12}>
        {configuration?.code ? (
          <GoldPricingList />
        ) : loading ? null : (
          <h4>Configuration Not Found</h4>
        )}
      </Grid>

      <Grid item xs={12} sx={{ minHeight: "321px" }}>
        {" "}
        {/* Setting a default minHeight */}
        <LazyLoad>
          <PuritiesList />
        </LazyLoad>
      </Grid>

      <Grid item xs={12} sx={{ minHeight: "321px" }}>
        {" "}
        {/* Adjust minHeight based on expected content */}
        <LazyLoad>
          <VendorCodesList />
        </LazyLoad>
      </Grid>

      <Grid item xs={12} sx={{ minHeight: "321px" }}>
        <LazyLoad>
          <SubCategoryList />
        </LazyLoad>
      </Grid>

      <Grid item xs={12} sx={{ minHeight: "321px" }}>
        <LazyLoad>
          <CategoryList />
        </LazyLoad>
      </Grid>

      <Grid item xs={12} sx={{ minHeight: "321px" }}>
        <LazyLoad>
          <CollectionLineList />
        </LazyLoad>
      </Grid>
    </Grid>
  );
};

export default MasterData;
