import React, { useRef, useState } from "react";
import enums from "helpers/enums";
import { Tab, Tabs } from "@material-ui/core";
import {
  getProductImageUri,
  isDivisionControlled,
  selectedDivisionId,
  userDetails,
} from "@ui/Utils/helper";
import { getPaginatedData } from "@ui/ComponentUtils/blueprintAPIs";
import { useNavigate } from "react-router-dom/dist/index";
import MatTable from "@ui/components/MatTable";
import AdvanceSearchFilter from "@ui/Controls/Search/AdvanceSearchFilter";
import Header from "@ui/ComponentUtils/Header";
import CustomEventEmitter from "@ui/Utils/CustomEventEmitter";
import { syncRecordsToDiva } from "views/Inventory/api";

const columns = [
  {
    title: "Style No",
    field: "styleNo",
  },
  {
    title: "SKU",
    field: "sku",
  },
  {
    title: "Category",
    field: "category.name",
  },
  {
    title: "Image",
    render: (row) => (
      <img src={getProductImageUri(row)} style={{ height: 50, width: 50 }} />
    ),
  },
  {
    title: "Stock Status",
    field: "stockStatus",
  },
];

const synced = "Synced",
  notSynced = "Not Synced";

const DivaList = () => {
  const model = enums.models.products,
    searchFields = ["styleNo", "sku", "category.name", "stockStatus"],
    headerTitle = "Diva Sync",
    hideAction = {},
    onRowClick = true;

  const [listPageTableQuery, setListPageTableQuery] = useState({
    pageSize: 10,
  });
  const [selectedRows, setSelectedRows] = useState([]);
  const navigate = useNavigate();
  const tableRef = useRef();
  const [currentTab, setCurrentTab] = useState(synced);
  const [filterCriteria, setFilterCriteria] = useState([]);

  const getData = async (query = {}) => {
    setListPageTableQuery(query);
    const tabCondition = {
      $or: [{ divaSynced: currentTab === synced }],
    };
    if (currentTab === notSynced) {
      tabCondition.$or.push({ divaSynced: { $exists: false } });
    }

    if (
      query.criteria?.[query.criteria.length - 1]?.searchElements?.length ||
      filterCriteria?.[filterCriteria.length - 1]?.searchElements?.length
    ) {
      if (query.criteria?.[0]?.$or) {
        query.criteria.unshift(tabCondition);
      } else {
        query.criteria = query.criteria || [];
        query.criteria[0] = tabCondition;
      }
    } else {
      query.criteria = [tabCondition, {}];
    }

    //backend sorting
    if (query.orderBy) {
      query.sort = {
        [query.orderBy.field]: query.orderDirection,
      };
    }
    query = {
      ...query,
    };

    try {
      let response = await getPaginatedData(model, query);

      if (response && response.paginationInfo) {
        let records = response.records || [];

        // records = await overRideRecords(records);

        return {
          data: records || [],
          page: response.paginationInfo.currentPageNo - 1 || 0,
          totalCount: response.paginationInfo.totalRecords || 0,
        };
      }
    } catch (error) {
      console.log(error);

      return {
        data: [],
        page: 0,
        totalCount: 0,
      };
    }
  };

  const onSelectionChange = (rows) => {
    let currentPageNum = tableRef.current.state.query.page;
    if (selectedRows && selectedRows.length + 1 < currentPageNum) {
      for (let index = selectedRows.length; index < currentPageNum; index++) {
        selectedRows[index] = [];
      }
    }
    selectedRows && selectedRows.splice(currentPageNum, 1, rows);
    setSelectedRows(rows);
  };

  const handleSearch = (filterQuery = []) => {
    const queryData = {
      pageSize: listPageTableQuery.pageSize,
      page: 0,
      criteria: filterQuery,
    };
    setFilterCriteria(filterQuery);
    tableRef.current.onQueryChange(queryData);
  };

  const handleChange = (_, tab) => {
    tableRef?.current?.onQueryChange &&
      tableRef.current.onQueryChange({
        page: 0,
        pageSize: listPageTableQuery.pageSize,
      });
    setCurrentTab(tab);
    setSelectedRows();
  };

  return (
    <div>
      <Header
        links={[]}
        pageTitle={headerTitle}
        actions={[
          {
            label: "Sync",
            iconName: "sync",
            tooltip: "Sync to Diva",
            hidden:
              !userDetails?.client?.appConfig?.["data-engine"]?.divisionLevel?.[
                isDivisionControlled ? selectedDivisionId : "default"
              ]?.integrateWithDiva,
            onClick: async () => {
              try {
                const res = await syncRecordsToDiva();

                CustomEventEmitter.emit(
                  "alert_success",
                  `${res.passed?.length} records Synced Successfully. `
                );
                handleChange(null, currentTab);
              } catch (err) {
                console.log(err);
                CustomEventEmitter.emit(
                  "alert_error",
                  err?.response?.data?.message
                );
              }
            },
          },
        ]}
      />

      <AdvanceSearchFilter
        searchFields={searchFields}
        handleSearch={handleSearch}
      />

      <Tabs
        style={{ position: "relative", zIndex: "1", maxWidth: "58%" }}
        value={currentTab}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="on"
        indicatorColor="primary"
        textColor="primary"
        aria-label="object page tabs"
      >
        <Tab value={synced} key={synced} label={synced} id={synced} />
        <Tab
          value={notSynced}
          key={notSynced}
          label={notSynced}
          id={notSynced}
        />
      </Tabs>

      <MatTable
        tableRef={tableRef}
        columns={columns}
        data={(query) => getData(query)}
        options={{
          selection: !hideAction.selection,
          search: false,
          pageSize: listPageTableQuery.pageSize,
        }}
        onRowClick={(_, selectedRow) => {
          onRowClick && navigate(`/${model}/${selectedRow.code}`);
        }}
        onSelectionChange={onSelectionChange}
      />
    </div>
  );
};

export default DivaList;
