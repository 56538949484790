import React from "react";
import enums from "helpers/enums";
import GeneralListPage from "@ui/ComponentUtils/GeneralListPage";

const columns = [
  {
    title: "Name",
    field: "name",
  },
  {
    title: "Description",
    field: "description",
  },
];

const DivisionsList = () => {
  return (
    <GeneralListPage
      columns={columns}
      model={enums.models.divisions}
      headerTitle="Divisions"
      hideAction={{ delete: true, selection: true }}
      searchFields={["name"]}
    />
  );
};

export default DivisionsList;
