import React from "react";
import enums from "helpers/enums";
import GeneralListPage from "@ui/ComponentUtils/GeneralListPage";
import { formatDateToRead } from "@ui/Utils/helper";

const columns = [
  {
    title: "Name",
    field: "name",
  },
  {
    title: "TSPL Commands",
    field: "tsplCommands",
  },
];

const PrintemplatesList = () => {
  return (
    <GeneralListPage
      columns={columns}
      model={enums.models.printerTemplates}
      headerTitle="Tag Templates"
      searchFields={["name"]}
      hideAction={{ delete: true }}
    />
  );
};

export default PrintemplatesList;
