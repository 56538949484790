import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Tooltip,
} from "@mui/material";
import { useFieldArray, useFormContext } from "react-hook-form";
import AddIcon from "@mui/icons-material/Add";
import MainSection from "@ui/MuiComponents/Section/MainSection";
import RHTextField from "@ui/ReactHookFormControls/RHTextField";
import Label from "@ui/components/Label";
import RHAutoComplete from "@ui/ReactHookFormControls/RHAutoComplete";
import RHCheckbox from "@ui/ReactHookFormControls/RHCheckbox";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import MyHoverIcons from "@ui/assets/commonIcons/customIcons/MyHoverIcons";
import _ from "lodash";

const PriceLessConfig = ({ customers, configuration, isEditable }) => {
  const formMethods = useFormContext();
  const { control, setValue, watch } = formMethods;

  const { fields, append, remove } = useFieldArray({
    control: control,
    name: "priceLessConfigs",
  });
  const priceLessConfigs = watch(`priceLessConfigs`);

  const configPriceFields = (configuration.fields || []).filter(
    (field) => field.checked && field.priceField
  );

  const handleAddRow = () => {
    append({
      slab: "",
      users: [],
      priceField: _.cloneDeep(configPriceFields).reduce((acc, field) => {
        acc[field.name] = {
          label: field.label,
          pricePerWeight: field.pricePerWeight,
          pricePerPiece: field.pricePerPiece,
          includeForTotalExtrasPrice: field.includeForTotalExtrasPrice,
        };
        return acc;
      }, {}),
    });
  };

  return (
    <MainSection
      title="Price Less Config"
      titleStyle={{ color: "#784c25" }}
      style={{ marginTop: "24px", backgroundColor: "#d0f1f2" }}
    >
      <TableContainer component={Paper} sx={{ overflowX: "scroll" }}>
        <Table
          sx={{
            minWidth: 650,
            backgroundColor: "#d4fdff",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
          }}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              <TableCell align="right" style={{ maxWidth: "120px" }}>
                {isEditable ? (
                  <IconButton color="primary" onClick={handleAddRow}>
                    <Tooltip title="SLAB it">
                      <AddIcon />
                    </Tooltip>
                  </IconButton>
                ) : (
                  " "
                )}
              </TableCell>
              <TableCell style={{ minWidth: "160px" }}>Slab</TableCell>
              <TableCell style={{ minWidth: "300px" }}>Customers</TableCell>
              {configPriceFields.map((field) => (
                <TableCell
                  key={field.name}
                  // style={{
                  //   maxWidth: "200px",
                  // }}
                >
                  {field.label +
                    " " +
                    (field.pricePerPiece
                      ? "(perPiece)"
                      : field.pricePerWeight
                      ? "(perWeight)"
                      : "")}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {fields.map((slab, slabIndex) => (
              <TableRow key={slab.id}>
                <TableCell style={{ textAlign: "center" }}>
                  {isEditable && slabIndex !== 0 ? (
                    <MyHoverIcons
                      muiIcon={
                        <RemoveCircleIcon
                          style={{ fontSize: "32px", color: "grey" }}
                        />
                      }
                      muiIconHover={
                        <RemoveCircleOutlineIcon
                          style={{ fontSize: "44px", color: "black" }}
                        />
                      }
                      onClick={() => remove(slabIndex)}
                      tooltip={`REMOVE it`}
                    />
                  ) : null}
                  {/* <DynamicRowPercentage
                    slabIndex={slabIndex}
                    control={control}
                  /> */}
                </TableCell>
                <TableCell>
                  {isEditable && slabIndex !== 0 ? (
                    <RHTextField
                      placeholder="Slab"
                      name={`priceLessConfigs[${slabIndex}].slab`}
                    />
                  ) : (
                    <Label value={slab.slab} />
                  )}
                </TableCell>
                <TableCell style={{ minWidth: "300px" }}>
                  {isEditable && slabIndex !== 0 ? (
                    <RHAutoComplete
                      options={customers.filter((customer) => {
                        return !priceLessConfigs.find(
                          (originalSlab, originalSlabIndex) =>
                            originalSlabIndex !== slabIndex &&
                            (originalSlab.users || []).find(
                              (existingDiscountUser) =>
                                existingDiscountUser?._id === customer._id
                            )
                        );
                      })}
                      placeholder="Legends"
                      name={`priceLessConfigs[${slabIndex}].users`}
                      getOptionLabel={(opt) =>
                        opt.firstName + " " + opt.lastName + ` (${opt.phone})`
                      }
                      getOptionSelected={(opt, val) => opt._id === val._id}
                      multiple={true}
                    />
                  ) : (
                    <Label
                      value={
                        slabIndex !== 0
                          ? (slab.users || [])
                              .map(
                                (user) =>
                                  (user.firstName || "") +
                                  " " +
                                  (user.lastName || "") +
                                  ` (${user.phone})`
                              )
                              .join(", ")
                          : "All"
                      }
                    />
                  )}
                </TableCell>
                {configPriceFields.map((priceField, priceFieldIndex) => (
                  <TableCell key={priceFieldIndex} style={{ width: "100%" }}>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <RHCheckbox
                        disabled={slabIndex === 0 || !isEditable}
                        name={`priceLessConfigs[${slabIndex}].priceField.${priceField.name}.includeForTotalExtrasPrice`}
                        label={"#include"}
                        // defaultValue={null}
                      />

                      <RHCheckbox
                        disabled={slabIndex === 0 || !isEditable}
                        name={`priceLessConfigs[${slabIndex}].priceField.${priceField.name}.pricePerWeight`}
                        label={"#weight"}
                        onChange={(event) => {
                          setValue(
                            `priceLessConfigs[${slabIndex}].priceField.${priceField.name}.pricePerPiece`,
                            !event.target.checked
                          );
                        }}
                      />
                      <RHCheckbox
                        disabled={slabIndex === 0 || !isEditable}
                        name={`priceLessConfigs[${slabIndex}].priceField.${priceField.name}.pricePerPiece`}
                        label={"#pieces"}
                        onChange={(event) => {
                          setValue(
                            `priceLessConfigs[${slabIndex}].priceField.${priceField.name}.pricePerWeight`,
                            !event.target.checked
                          );
                        }}
                      />
                      {isEditable ? (
                        <RHTextField
                          name={`priceLessConfigs[${slabIndex}].priceField.${priceField.name}.customPrice`}
                          type="number"
                          placeholder={
                            slabIndex === 0 ? "price" : "custom price"
                          }
                          // disabled={
                          //   slab.priceField?.[priceField.name]
                          //     ?.includeForTotalExtrasPrice
                          //     ? false
                          //     : true
                          // }
                        />
                      ) : (
                        <Label
                          value={
                            slab.priceField?.[priceField.name]?.customPrice
                          }
                        />
                      )}
                    </div>
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </MainSection>
  );
};
// const DynamicRowPercentage = ({ slabIndex, control }) => {
//   const priceFieldConfigs =
//     useWatch({
//       control,
//       name: `priceLessConfigs[${slabIndex}].priceField`,
//     }) || {};

//   const [display, setDisplay] = useState(0);
//   useEffect(() => {
//     const priceFieldsArray = Object.values(priceFieldConfigs || {});
//     const selectedCount = priceFieldsArray.filter(
//       (field) => field.includeForTotalExtrasPrice
//     ).length;
//     const percentage = Math.round(
//       (selectedCount / priceFieldsArray.length) * 100
//     );
//     setDisplay(percentage);
//   }, [priceFieldConfigs]);
//   return <b>{`${display || 0}%`}</b>;
// };

export default PriceLessConfig;
