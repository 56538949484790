import React, { useState } from "react";
import enums from "helpers/enums";
import GeneralListPage from "@ui/ComponentUtils/GeneralListPage";
import {
  formatDateToRead,
  isAdmin,
  isSuperAdmin,
  uniqueArray,
} from "@ui/Utils/helper";

const plcS = (row) =>
  uniqueArray((row.priceLessConfigs || []).map((slab) => slab.slab)).join(", ");

const slcS = (row) =>
  uniqueArray((row.stoneLessConfigs || []).map((slab) => slab.slab)).join(", ");

const columns = [
  {
    title: "Slabs",
    render: (row) => {
      const pricelessSlabs = plcS(row);
      const stonelessSlabs = slcS(row);

      return (
        <div>
          <div>
            {`StoneLess: `}
            <b>{stonelessSlabs}</b>
          </div>

          <div>
            {`PriceLess: `}
            <b>{pricelessSlabs}</b>
          </div>
        </div>
      );
    },
  },
  {
    title: "Labor Varieties",
    render: (row) => {
      let vendorCodeSum = 0,
        subCategorySum = 0,
        categorySum = 0,
        collectionLineSum = 0;

      Object.values({
        ...row.vendorCode,
      }).map((labor) => {
        if (labor?.laborPrice) {
          vendorCodeSum += 1;
        }
      });
      Object.values({
        ...row.subCategory,
      }).map((labor) => {
        if (labor?.laborPrice) {
          subCategorySum += 1;
        }
      });
      Object.values({
        ...row.category,
      }).map((labor) => {
        if (labor?.laborPrice) {
          categorySum += 1;
        }
      });
      Object.values({
        ...row.collectionLine,
      }).map((labor) => {
        if (labor?.laborPrice) {
          collectionLineSum += 1;
        }
      });
      return `Vendor Codes: ${vendorCodeSum}, Sub Category: ${subCategorySum}, Category: ${categorySum}, Collection Line: ${collectionLineSum}`;
    },
  },
  {
    title: "Created Date",
    field: "dateCreated",
    render: (row) => formatDateToRead(row?.dateCreated),
  },
  {
    title: "Created By",
    render: (row) => row?.createdBy?.firstName + " " + row?.createdBy?.lastName,
  },
];

const GoldPricingList = () => {
  const [hideCreate, setHideCreate] = useState(true);

  const overRideRecords = (records) => {
    if (!records?.length && (isAdmin || isSuperAdmin)) {
      setHideCreate(false);
    }

    return records;
  };

  return (
    <GeneralListPage
      columns={columns}
      model={enums.models.goldPricing}
      headerTitle="Metal Pricing"
      // searchFields={["availableCodes"]}
      overRideRecords={overRideRecords}
      paginationMeta={{ pageSize: 5 }}
      hideAction={{
        delete: true,
        search: true,
        selection: true,
        create: hideCreate,
      }}
    />
  );
};

export default GoldPricingList;
