import React from "react";
import enums from "helpers/enums";
import GeneralListPage from "@ui/ComponentUtils/GeneralListPage";
import { formatDateToRead } from "@ui/Utils/helper";

const columns = [
  {
    title: "Diamond Type",
    field: "diamondType",
  },
  {
    title: "Min Carat",
    field: "minCarat",
  },
  {
    title: "Max Carat",
    field: "maxCarat",
  },
  {
    title: "Max Price",
    field: "ratePerCarat",
  },
  {
    title: "Discounts",
    render: (row) =>
      (row.discountGroup || [])
        .map((group) => group.customPrice)
        .filter((e) => e)
        .join(", "),
  },
  {
    title: "Created Date",
    field: "dateCreated",
    render: (row) => formatDateToRead(row?.dateCreated),
  },
  {
    title: "Created By",
    render: (row) => row?.createdBy?.firstName + " " + row?.createdBy?.lastName,
  },
];

const DiamondPricingList = () => {
  return (
    <GeneralListPage
      columns={columns}
      model={enums.models.diamondPricing}
      headerTitle="Diamond Pricing"
      searchFields={["diamondType"]}
      numberFields={["minCarat", "maxCarat"]}
      hideAction={{ delete: true, selection: true }}
      paginationMeta={{ pageSize: 5 }}
    />
  );
};

export default DiamondPricingList;
