import React, { useState } from "react";
import { Grid } from "@material-ui/core";

import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import enums from "helpers/enums";
import { defaultPageSize } from "./List";

import Classify from "./Clasify";

const Classifications = ({
  classification = {},
  setPagination,
  fetchStocks,
  pagination,
  mongooseQuery,
  metaInfo,
}) => {
  const [showTopPaginationArrow, setShowTopPaginationArrow] = useState(false);

  return (
    <Grid container spacing={3}>
      <PaginationActions
        setPagination={setPagination}
        fetchStocks={fetchStocks}
        pagination={pagination}
        mongooseQuery={mongooseQuery}
        metaInfo={metaInfo}
        style={{ marginTop: "-48px" }}
      />

      <Grid item xs={12}>
        <Classify
          classification={classification}
          setShowTopPaginationArrow={setShowTopPaginationArrow}
        />
      </Grid>

      {showTopPaginationArrow ? (
        <PaginationActions
          setPagination={setPagination}
          fetchStocks={fetchStocks}
          pagination={pagination}
          mongooseQuery={mongooseQuery}
          metaInfo={metaInfo}
        />
      ) : null}
    </Grid>
  );
};

const PaginationActions = ({
  setPagination,
  fetchStocks,
  pagination,
  mongooseQuery,
  metaInfo,
  style,
}) => {
  return (
    <Grid
      item
      xs={12}
      container
      style={{ flexDirection: "col", paddingTop: "16px", ...style }}
      justify="center"
    >
      <KeyboardDoubleArrowLeftIcon
        style={styles.arrow}
        onClick={() => {
          if (pagination.page - 1 < 0) {
            return;
          }
          let page = pagination.page - 1;
          const pageObj = {
            page: page,
            pageSize: defaultPageSize,
          };

          window.sessionStorage.setItem(
            enums.models.products + "/inventoryPagination",
            JSON.stringify(pageObj)
          );
          setPagination(pageObj);
          fetchStocks(mongooseQuery);
        }}
      />
      <span
        style={{
          fontSize: "28px",
          fontWeight: "bold",
          marginTop: "18px",
        }}
      >
        {`${metaInfo.currentPageNo || ""} / ${metaInfo.totalPages || ""}`}{" "}
      </span>
      <KeyboardDoubleArrowRightIcon
        style={styles.arrow}
        onClick={() => {
          if (pagination.page + 1 < metaInfo.totalPages) {
            const pageObj = {
              page: pagination.page + 1,
              pageSize: defaultPageSize,
            };

            setPagination(pageObj);

            window.sessionStorage.setItem(
              enums.models.products + "/inventoryPagination",
              JSON.stringify(pageObj)
            );

            fetchStocks(mongooseQuery);
          }
        }}
      />
    </Grid>
  );
};

const styles = {
  arrow: { cursor: "pointer", fontSize: "69px", margin: "5px", color: "grey" },
};
export default Classifications;
