import React, { useEffect, useState } from "react";
import MainSection from "@ui/MuiComponents/Section/MainSection";
import DiscountGroup from "./DiscountGroup";
import enums from "helpers/enums";
import { search } from "@ui/ComponentUtils/blueprintAPIs";
import { useFormContext } from "react-hook-form";

const DetailContent = ({ isEditable }) => {
  const [customers, setCustomers] = useState([]);
  const { watch } = useFormContext();
  const values = watch();

  useEffect(() => {
    (async () => {
      try {
        setCustomers(
          (await search(enums.models.users, {
            filterColumns: { firstName: 1, lastName: 1, _id: 1, phone: 1 },
          })) || []
        );
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  return (
    <MainSection
      title={"Discount Group"}
      style={{ marginBottom: "24px", height: "100%" }}
    >
      <DiscountGroup
        isEditable={isEditable}
        customers={customers}
        discountGroupField="discountGroup"
        totalPrice={values.ratePerCarat}
        isDiamondPricingFlag={true}
      />
    </MainSection>
  );
};

export default DetailContent;
