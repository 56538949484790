import React from "react";
import enums from "helpers/enums";
import GeneralListPage from "@ui/ComponentUtils/GeneralListPage";
import { formatDateToRead, imageExpired, oopsNotFound } from "@ui/Utils/helper";

const columns = [
  {
    title: "Name",
    field: "name",
  },
  {
    title: "Image",
    render: (row) => (
      <img
        src={row?.images?.[0]?.signedUrl || oopsNotFound}
        alt="img"
        style={{ height: 50, width: 50 }}
        onError={(event) => {
          event.target.src = imageExpired;
        }}
      />
    ),
  },
  {
    title: "Created Date",
    field: "dateCreated",
    render: (row) => formatDateToRead(row?.dateCreated),
  },
  {
    title: "Created By",
    render: (row) => row?.createdBy?.firstName + " " + row?.createdBy?.lastName,
  },
];

const CollectionLineList = () => {
  return (
    <GeneralListPage
      columns={columns}
      model={enums.models.collectionLines}
      headerTitle="Collection Lines"
      searchFields={["name"]}
      hideAction={{ delete: true, selection: true }}
      paginationMeta={{ pageSize: 5 }}
    />
  );
};

export default CollectionLineList;
