import GeneralSection from "@ui/ComponentUtils/GeneralSection";
import Header from "@ui/ComponentUtils/Header";
import React from "react";

import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useFormContext } from "react-hook-form";
import enums from "helpers/enums";

const DetailHeader = (props) => {
  const { isEditable, onSubmit, setIsEditable } = props;
  const formMethods = useFormContext();
  const { setValue } = formMethods;
  const values = formMethods.watch();
  const model = enums.models.printerTemplates;

  const params = useParams();
  const navigate = useNavigate();

  const handleCancel = () => {
    if (window.confirm("Are you sure to cancel? Changes will be discarded.")) {
      if (params?.code === "create") {
        navigate("/" + model);
      } else {
        formMethods.reset();
        setIsEditable(false);
      }
    }
  };

  const entityFields = [
    {
      name: "name",
      label: "Name",
      fieldType: enums.fieldTypes.textField,
      xs: 12,
    },

    {
      name: "tsplCommands",
      label: "TSPL Commands",
      fieldType: enums.fieldTypes.textField,
      multiline: true,
      rows: 30,
      xs: 12,
      style: {
        fontFamily: "Courier New",
        fontWeight: "bold",
      },
      valueStyle: {
        fontFamily: "Courier New",
        fontWeight: "bold",
      },
      onKeyDown: (event) => {
        var x = event.keyCode;
        if (x === 9) {
          event.preventDefault();
          const value = formMethods.getValues().tsplCommands;
          setValue("tsplCommands", value + "    ");
        }
      },
      placeholder: `[  
        \`QR Start\`,
        \`SIZE 82 mm, 13 mm\`,
        \`GAP 3 mm,0 mm\`,
        \`SPEED 4\`,
        \`DENSITY 15\`,
        \`SET RIBBON ON\`,
        \`DIRECTION 0,0\`,
        \`REFERENCE 0,0\`,
        \`OFFSET 0 mm\`,
        \`SET PEEL OFF\`,
        \`SET CUTTER OFF\`,
        \`SET PARTIAL_CUTTER OFF\`,
        \`SET TEAR ON\`,
        \`CLS\`,
        \`CODEPAGE 1252\`,
        \`TEXT 30,43,"ROMAN.TTF",0,7,7,"N Wt: \${stock.netWeight} GM"\`,  
        
        /*** for other fields which are not required fields, use ?. and || '' with stock object. 
                {ex: stock.diamondDetails?.[0]?.symmetry || ''} -> to not print undefined in printed tag 
        ***/

        \`TEXT 30,63,"ROMAN.TTF",0,7,7,"S Wt:  \${stock.lessWeight} GM"\`,
        \`TEXT 30,83,"ROMAN.TTF",0,7,7,"G Wt: \${stock.grossWeight} GM"\`,
        \`TEXT 30,103,"0",0,7,7,"Item:  \${stock.category.name}"\`,
        \`TEXT 150,43,"ROMAN.TTF",0,7,7,"D No.: \${stock.styleNo}"\`,
        \`TEXT 150,63,"ROMAN.TTF",0,7,7,"Color: \${stock.color || ""}"\`,
        \`TEXT 150,83,"ROMAN.TTF",0,7,7,"Qty: 1"\`,
        \`TEXT 150,103,"ROMAN.TTF",0,6,6,"\${stock.purity.value} KT"\`,
        \`QRCODE 400,120,H,3,A,180,M2,S7,"\${stock.sku}\`,
        \`PRINT 1,1\`,
        \`QR End\`,
    ]`,
    },
  ];

  const links = [
    {
      name: "Tag Template",
      url: "/" + model,
      isDisplayLink: true,
    },
    {
      name: values.name,
      url: "",
      isDisplayLink: false,
    },
  ];

  const actions = [
    {
      label: "Cancel",
      iconName: "cancel",
      hidden: !isEditable,
      onClick: handleCancel,
    },
    {
      label: "Save",
      iconName: "save",
      hidden: !isEditable,
      type: "submit",
      onClick: formMethods.handleSubmit(onSubmit),
    },
    {
      label: "Edit",
      iconName: "edit",
      hidden: isEditable,
      onClick: () => {
        setIsEditable(true);
      },
    },
  ];

  return (
    <>
      <Header links={links} pageTitle={"Tag Template"} actions={actions} />

      <GeneralSection
        // actions={sectionActions}
        fields={entityFields}
        titleStyle={{ color: "red" }}
        title={"#code..."}
        isEditable={isEditable}
        values={values}
      />
    </>
  );
};

export default DetailHeader;
