import React from "react";
import enums from "helpers/enums";
import GeneralListPage from "@ui/ComponentUtils/GeneralListPage";
import { isDivisionControlled } from "@ui/Utils/helper";

const columns = [
  {
    title: "Name",
    field: "firstName",
    render: (row) => row?.firstName + " " + row?.lastName,
  },
  {
    title: "Divisions",
    field: "division.name",
    render: (row) =>
      row.division?.map && row.division?.map((div) => div?.name).join(", "),
    hidden: !isDivisionControlled,
  },
  { title: "Role", field: "role" },
  {
    title: "Zones",
    field: "zones",
    render: (row) => (row.zones || []).join(", "),
  },
  {
    title: "Phone",
    field: "phone",
  },
  {
    title: "Email",
    field: "email",
  },
];

const UsersList = () => {
  return (
    <GeneralListPage
      searchFields={["role", "division.name", "phone", "email", "zones"]}
      userFields={["$firstName", "$lastName"]}
      columns={columns}
      model={enums.models.iam.users}
      headerTitle="Employees"
      hideAction={{ delete: true, selection: true }}
    />
  );
};

export default UsersList;
