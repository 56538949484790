import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import CustomEventEmitter from "@ui/Utils/CustomEventEmitter";
import {
  createRecord,
  getByCode,
  getSingleRecord,
  search,
  updateRecord,
} from "@ui/ComponentUtils/blueprintAPIs";

import enums from "helpers/enums";
import DetailHeader from "./components/DetailHeader";
import DetailContent from "./components/DetailContent";
import _ from "lodash";

// Convert wastage and customWastage from 0-1 to 0-100 for the reset values
const convertToPercentage = (obj) => {
  Object.keys(obj).forEach((key) => {
    if (
      obj[key]?.wastage !== undefined &&
      obj[key].wastage !== null &&
      obj[key].wastage !== ""
    ) {
      obj[key].wastage = Number((obj[key].wastage * 100).toFixed(2)); // Convert wastage back to 0-100
    }
    if (Array.isArray(obj[key]?.discountGroup)) {
      obj[key].discountGroup.forEach((group) => {
        if (
          group.customWastage !== undefined &&
          group.customWastage !== null &&
          group.customWastage !== ""
        ) {
          group.customWastage = Number((group.customWastage * 100).toFixed(2)); // Convert customWastage back to 0-100
        }
      });
    }
  });
};

const maxDecimalPlaces = (value, max = 2) => {
  if (value === null || value === undefined) return true; // Allow null/undefined values
  const decimalPart = value.toString().split(".")[1];
  return !decimalPart || decimalPart.length <= max;
};

const discountGroupSchema = (key) =>
  yup
    .object()
    .shape({
      wastage: yup
        .number()
        .typeError("Invalid value")
        .nullable()
        .min(0, "0 - 100")
        .max(100, "0 - 100")
        .transform((_, val) =>
          val === "" || val === null || val === undefined ? null : Number(val)
        )
        .test("max-decimal-places", "2 decimals max", (value) =>
          maxDecimalPlaces(value)
        ),
      laborPrice: yup
        .number()
        .typeError("Invalid value")
        .nullable()
        .transform((_, val) =>
          val === "" || val === null || val === undefined ? null : Number(val)
        ),

      discountGroup: yup.array().of(
        yup.object().shape({
          customWastage: yup
            .number()
            .typeError("Invalid value")
            .nullable()
            .min(0, "0 - 100")
            .max(100, "0 - 100")
            .transform((_, val) =>
              val === "" || val === null || val === undefined
                ? null
                : Number(val)
            )
            .test("max-decimal-places", "2 decimals max", (value) =>
              maxDecimalPlaces(value)
            ),

          customPrice: yup
            .number()
            .typeError("Invalid value")
            .nullable()
            .transform((_, val) =>
              val === "" || val === null || val === undefined
                ? null
                : Number(val)
            ),
        })
      ),
    })
    .nullable()
    .label(key);

const validationSchema = yup.object().shape({
  stoneLessConfigs: yup.array().of(
    yup.object({
      slab: yup
        .string()
        .matches(/^\S+$/, "Use one word nickname")
        .required("Slab is required"),
    })
  ),
  priceLessConfigs: yup.array().of(
    yup.object({
      slab: yup
        .string()
        .matches(/^\S+$/, "Use one word nickname")
        .required("Slab is required"),
    })
  ),

  vendorCode: yup.lazy((value) => {
    const dynamicKeys = Object.keys(value || {});

    return yup.object().shape(
      dynamicKeys.reduce((schema, key) => {
        schema[key] = discountGroupSchema(key);
        return schema;
      }, {})
    );
  }),
  subCategory: yup.lazy((value) => {
    const dynamicKeys = Object.keys(value || {});
    return yup.object().shape(
      dynamicKeys.reduce((schema, key) => {
        schema[key] = discountGroupSchema(key);
        return schema;
      }, {})
    );
  }),
  category: yup.lazy((value) => {
    const dynamicKeys = Object.keys(value || {});
    return yup.object().shape(
      dynamicKeys.reduce((schema, key) => {
        schema[key] = discountGroupSchema(key);
        return schema;
      }, {})
    );
  }),
  collectionLine: yup.lazy((value) => {
    const dynamicKeys = Object.keys(value || {});
    return yup.object().shape(
      dynamicKeys.reduce((schema, key) => {
        schema[key] = discountGroupSchema(key);
        return schema;
      }, {})
    );
  }),
});

const entitySchema = {
  // setting initialValues for both create and update in useEffect on params.code...
  priceLessConfigs: [],
  stoneLessConfigs: [],
};

const GoldPricingDetail = () => {
  const formMethods = useForm({
    defaultValues: entitySchema,
    mode: "all",
    resolver: yupResolver(validationSchema),
  });
  const navigate = useNavigate();

  const params = useParams();
  const model = enums.models.goldPricing;
  const [isEditable, setIsEditable] = useState(false);
  const [frontEndOptions, setFrontEndOptions] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [configuration, setConfiguration] = useState({});

  useEffect(() => {
    (async () => {
      try {
        setCustomers(
          (await search(enums.models.users, {
            filterColumns: { firstName: 1, lastName: 1, _id: 1, phone: 1 },
          })) || []
        );
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const id = params?.code;

        // Common promises
        const categoryPromise = search(enums.models.category),
          subCategoryPromise = search(enums.models.subCategory),
          collectionLinePromise = search(enums.models.collectionLines),
          vendorCodePromise = getSingleRecord(
            enums.models["vendor-portal"].vendorCodes
          ),
          configurationPromise = getSingleRecord(enums.models.configuration);

        // Conditionally include goldPricePromise
        const promises = [
          categoryPromise,
          subCategoryPromise,
          collectionLinePromise,
          vendorCodePromise,
          configurationPromise,
        ];
        if (id !== "create") {
          promises.push(getByCode(model, id));
        }

        let [
          categories,
          subCategories,
          collectionLines,
          vendorCodes,
          configurationValue,
          goldPriceRecord,
        ] = await Promise.all(promises);

        if (!configurationValue) {
          CustomEventEmitter.emit("alert_error", "Configuration not found");
          return navigate("/" + enums.models.masterData);
        }

        if (goldPriceRecord) {
          ["vendorCode", "subCategory", "category", "collectionLine"].forEach(
            (key) => {
              if (goldPriceRecord[key])
                convertToPercentage(goldPriceRecord[key]);
            }
          );
        }

        // till the day configuration.schema is handled and migrated with diamondFields as required in that future time..
        configurationValue.fields.unshift({
          checked: configurationValue.diamondFields,
          label: "Standard Diamonds",
          name: "diamondFields",
          weightField: true,
          includeForLessWeight: true,
          inputInCts: true,
        });

        // Prepare frontEnd options
        const frontEnd = {
          category: categories,
          subCategory: subCategories,
          collectionLine: collectionLines,
          vendorCode:
            (vendorCodes?.availableCodes || [])
              .filter((vcode) => vcode?.toString().trim())
              .map((vcode) => ({
                _id: vcode,
                name: vcode,
              })) || [],
        };

        const clonedConfiguration = _.cloneDeep(configurationValue);

        let res = {
          frontEnd,
          stoneLessConfigs: [],
          priceLessConfigs: [],
        };

        // Default configuration for stoneLessConfigs
        if (goldPriceRecord?.stoneLessConfigs?.length) {
          res.stoneLessConfigs = goldPriceRecord?.stoneLessConfigs || [];
        } else {
          res.stoneLessConfigs = [
            {
              slab: "Default",
              weightField: (clonedConfiguration.fields || [])
                .filter((field) => field.checked && field.weightField)
                .reduce((acc, field) => {
                  acc[field.name] = {
                    label: field.label,
                    includeForLessWeight: field.includeForLessWeight || false,
                    inputInCts: field.inputInCts || false,
                  };
                  return acc;
                }, {}),
            },
          ];
        }

        // Default configuration for priceLessConfigs
        if (goldPriceRecord?.priceLessConfigs?.length) {
          res.priceLessConfigs = goldPriceRecord?.priceLessConfigs || [];
        } else {
          res.priceLessConfigs = [
            {
              slab: "Default",
              priceField: clonedConfiguration.fields
                .filter((field) => field.checked && field.priceField)
                .reduce((acc, field) => {
                  acc[field.name] = {
                    label: field.label,
                    pricePerWeight: field.pricePerWeight || false,
                    pricePerPiece: field.pricePerPiece || false,
                    includeForTotalExtrasPrice:
                      field.includeForTotalExtrasPrice || false,
                  };
                  return acc;
                }, {}),
            },
          ];
        }

        if (id !== "create") {
          res = {
            ...(goldPriceRecord || {}),
            ...res,
          };
          formMethods.reset(res);
          setIsEditable(false);
        } else {
          formMethods.reset(res);
          setIsEditable(true);
        }
        setFrontEndOptions(frontEnd);
        setConfiguration(configurationValue);
      } catch (err) {
        navigate("/" + enums.models.masterData);
        console.log(err);
      }
    })();
  }, [params?.code]);

  const onSubmit = async (values, metaData) => {
    try {
      let res = {},
        action = "Created";

      // Convert wastage and customWastage from 0-100 to 0-1 before submission
      const convertToDecimal = (obj) => {
        Object.keys(obj).forEach((key) => {
          if (
            obj[key]?.wastage !== undefined &&
            obj[key].wastage !== null &&
            obj[key].wastage !== ""
          ) {
            obj[key].wastage = Number((obj[key].wastage / 100).toFixed(4)); // Convert wastage to 0-1
          }
          if (Array.isArray(obj[key]?.discountGroup)) {
            obj[key].discountGroup.forEach((group) => {
              if (
                group.customWastage !== undefined &&
                group.customWastage !== null &&
                group.customWastage !== ""
              ) {
                group.customWastage = Number(
                  (group.customWastage / 100).toFixed(4)
                ); // Convert customWastage to 0-1
              }
            });
          }
        });
      };

      // Convert all required fields to decimal
      ["vendorCode", "subCategory", "category", "collectionLine"].forEach(
        (key) => {
          if (values[key]) convertToDecimal(values[key]);
        }
      );

      // Remove unnecessary frontEnd field
      delete values.frontEnd;

      // This is temporary till the day we get to know why react-hook-form is making 1st row boolean keys undefined after adding new row everytime.. no clue
      Object.keys(values.stoneLessConfigs[0]?.weightField || {}).map(
        (weightFieldName) => {
          const matchedField = configuration.fields.find(
            (field) => field.checked && field.name === weightFieldName
          );
          if (matchedField) {
            values.stoneLessConfigs[0].weightField[
              weightFieldName
            ].includeForLessWeight = matchedField.includeForLessWeight;
            values.stoneLessConfigs[0].weightField[weightFieldName].inputInCts =
              matchedField.inputInCts;
          }
        }
      );
      // same reason as above.. poor hook-form..
      Object.keys(values.priceLessConfigs[0]?.priceField || {}).map(
        (priceFieldName) => {
          const matchedField = configuration.fields.find(
            (field) => field.checked && field.name === priceFieldName
          );
          if (matchedField) {
            values.priceLessConfigs[0].priceField[
              priceFieldName
            ].pricePerWeight = matchedField.pricePerWeight;
            values.priceLessConfigs[0].priceField[
              priceFieldName
            ].pricePerPiece = matchedField.pricePerPiece;
            values.priceLessConfigs[0].priceField[
              priceFieldName
            ].includeForTotalExtrasPrice =
              matchedField.includeForTotalExtrasPrice;
          }
        }
      );

      if (!values.code) {
        res = await createRecord(model, values);
        navigate(`/${model}/${res?.code}`);
      } else {
        res = await updateRecord(model, values);
        action = "Updated";
      }

      // Convert all required fields back to percentage for reset
      ["vendorCode", "subCategory", "category", "collectionLine"].forEach(
        (key) => {
          if (res[key]) convertToPercentage(res[key]);
        }
      );

      // Reset the form with updated values
      formMethods.reset({ ...res, frontEnd: frontEndOptions });
      setIsEditable(false);
      CustomEventEmitter.emit("alert_success", "Successfully " + action);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <FormProvider {...formMethods}>
      <DetailHeader
        isEditable={isEditable}
        onSubmit={onSubmit}
        setIsEditable={setIsEditable}
        customers={customers}
        configuration={configuration}
      />

      <DetailContent isEditable={isEditable} customers={customers} />
    </FormProvider>
  );
};

export default GoldPricingDetail;
