import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import CustomEventEmitter from "@ui/Utils/CustomEventEmitter";
import {
  createRecordWithHook,
  getByCode,
  updateRecordWithHook,
} from "@ui/ComponentUtils/blueprintAPIs";

import enums from "helpers/enums";
import DetailHeader from "./components/DetailHeader";
import DetailContent from "./components/DetailContent";

const validationSchema = yup.object().shape({
  diamondType: yup.string().nullable().required("Required"),
  minCarat: yup
    .number()
    .min(0)
    .typeError("must be a number")
    .required("Required"),
  maxCarat: yup
    .number()
    .min(0)
    .typeError("must be a number")
    .required("Required"),
  ratePerCarat: yup
    .number()
    .min(0)
    .typeError("must be a number")
    .required("Required"),

  discountGroup: yup.array().of(
    yup.object().shape({
      customWastage: yup
        .number()
        .typeError("0.0 - 100")
        .nullable()
        .min(0, "0.0 - 100")
        .max(100, "0.0 - 100")
        .transform((_, val) => (val !== "" ? Number(val) : 0)),
    })
  ),
});

const entitySchema = { discountGroup: [{ discountPercent: "", users: [] }] };

const DiamondPricingDetail = () => {
  const formMethods = useForm({
    defaultValues: entitySchema,
    mode: "all",
    resolver: yupResolver(validationSchema),
  });
  const navigate = useNavigate();

  const params = useParams();
  const model = enums.models.diamondPricing;
  const [isEditable, setIsEditable] = useState(false);

  useEffect(() => {
    (async () => {
      const id = params?.code;

      try {
        if (id !== "create") {
          const res = await getByCode(model, id);

          formMethods.reset(res);
          setIsEditable(false);
        } else {
          setIsEditable(true);
        }
      } catch (err) {
        navigate("/" + enums.models.masterData);
        console.log(err);
      }
    })();
  }, [params?.code]);

  const onSubmit = async (values, metaData) => {
    try {
      let res = {},
        action = "Created";

      if (!(values.minCarat <= values.maxCarat)) {
        return CustomEventEmitter.emit("alert_error", "Invalid Min Max Range");
      }

      if (!values._id) {
        res = await createRecordWithHook(model, values);
        navigate(`/${model}/${res?.code}`);
      } else {
        res = await updateRecordWithHook(model, values);
        action = "Updated";
      }
      setIsEditable(false);
      formMethods.reset(res);
      CustomEventEmitter.emit("alert_success", "Successfully " + action);
    } catch (err) {
      console.log(err);
      let message = err?.response?.data?.message || "Something went wrong";
      if (message?.toLowerCase().match("duplicate")) {
        message = "Diamond Pricing mater exists with this data";
      }
      CustomEventEmitter.emit("alert_error", message);
    }
  };

  return (
    <FormProvider {...formMethods}>
      <DetailHeader
        isEditable={isEditable}
        onSubmit={onSubmit}
        setIsEditable={setIsEditable}
      />

      <DetailContent isEditable={isEditable} />
    </FormProvider>
  );
};

export default DiamondPricingDetail;
