import CustomEventEmitter from "@ui/Utils/CustomEventEmitter";
import React, { useEffect, useRef, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useAuth } from "@ui/components/AuthProvider";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  createRecord,
  getByCode,
  paginate,
  updateRecord,
  uploadFiles,
} from "@ui/ComponentUtils/blueprintAPIs";

import _ from "lodash";

import GeneralSection from "@ui/ComponentUtils/GeneralSection";
import Header from "@ui/ComponentUtils/Header";
import enums from "helpers/enums";

const validationSchema = yup.object().shape({
  name: yup.string().required("Required *"),
  description: yup.string().required("Required *"),
});
const entitySchema = {
  firstName: "",
  lastName: "",
  phone: "",
  email: "",
};

const DivisionDetail = () => {
  const formMethods = useForm({
    defaultValues: entitySchema,
    mode: "all",
    resolver: yupResolver(validationSchema),
  });
  const navigate = useNavigate();

  const params = useParams();

  const auth = useAuth();
  const [isEditable, setIsEditable] = useState(false);

  const values = formMethods.watch();

  useEffect(() => {
    (async () => {
      const code = params?.code;

      try {
        if (code == "create") {
          setIsEditable(true);
        } else {
          const res = await getByCode(enums.models.divisions, code);
          formMethods.reset(res);
          setIsEditable(false);
        }
      } catch (err) {
        navigate(enums.models.divisions);
        console.log(err);
      }
    })();
  }, [params?.code]);

  const onSubmit = async (values) => {
    try {
      // if (values.password?.toString()?.trim()) {
      //   values.changePasswordFlag = true;
      // }
      if (!values.image?.location) {
        return CustomEventEmitter.emit(
          "alert_error",
          "Upload Image to continue"
        );
      }

      let action = "Created",
        res = {};
      if (!values.code) {
        res = await createRecord(enums.models.divisions, values);
      } else {
        res = await updateRecord(enums.models.divisions, values);
        action = "Updated";
      }
      navigate(`/${enums.models.divisions}/${res.code}`);

      setIsEditable(false);
      CustomEventEmitter.emit("alert_success", "Successfully " + action);

      window.location.reload();
    } catch (err) {
      console.log(err?.response?.data?.message);

      CustomEventEmitter.emit("alert_error", values.name + " Division exist");
    }
  };

  const handleCancel = () => {
    if (window.confirm("Are you sure to cancel? Changes will be discarded.")) {
      if (params?.id === "create") {
        navigate("/" + enums.models.divisions);
      } else {
        formMethods.reset();
        setIsEditable(false);
      }
    }
  };

  const entityFields = [
    {
      name: "name",
      label: "Name",
      fieldType: enums.fieldTypes.textField,
    },
    {
      name: "description",
      label: "Description",
      fieldType: enums.fieldTypes.textField,
    },
    {
      name: "image",
      label: "Image",
      fieldType: enums.fieldTypes.upload,
      single: true,
      accept: "image/*",
      model: enums.models.divisions,
      required: true,
      previewThumbnail: true,
    },
  ];

  const links = [
    {
      name: "Division",
      url: `#/${enums.models.divisions}`,
      isDisplayLink: true,
    },
    {
      name: values.name,
      url: "",
      isDisplayLink: false,
    },
  ];

  const actions = [
    {
      label: "Cancel",
      iconName: "cancel",
      hidden: !isEditable,
      onClick: handleCancel,
    },
    {
      label: "Save",
      iconName: "save",
      hidden: !isEditable,
      type: "submit",
      onClick: formMethods.handleSubmit(onSubmit),
    },
    {
      label: "Edit",
      iconName: "edit",
      hidden: isEditable,
      onClick: () => {
        setIsEditable(true);
      },
    },
  ];

  return (
    <FormProvider {...formMethods}>
      <Header links={links} pageTitle={"Divisions"} actions={actions} />

      <GeneralSection
        fields={entityFields}
        title="Information"
        isEditable={isEditable}
        values={values}
      />
    </FormProvider>
  );
};

export default DivisionDetail;
