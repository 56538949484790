import React, { useEffect, useState } from "react";
import { createFilterOptions } from "@mui/material/Autocomplete";
import { useFormContext } from "react-hook-form";
import enums from "helpers/enums";
import { search } from "@ui/ComponentUtils/blueprintAPIs";
import MainSection from "@ui/MuiComponents/Section/MainSection";
import Label from "@ui/components/Label";
import { Grid, Tab, Tabs, Typography, Box, Paper } from "@mui/material";
import RHTextField from "@ui/ReactHookFormControls/RHTextField";
import DiscountGroup from "views/DiamondPricing/Detail/components/DiscountGroup";
import { useFieldArray } from "react-hook-form";

const filter = createFilterOptions();

const DetailContent = ({ isEditable, customers = [] }) => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <MainSection title="Labor Pricing Priority" style={{ marginTop: "24px" }}>
      <Tabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="auto"
        sx={{
          padding: "10px",
          backgroundColor: "#fff3d1",

          marginBottom: "20px",
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
          borderRadius: "5px",
          "& .MuiTab-root": {
            marginRight: "40px",
            "&:hover": {
              backgroundColor: "white",
              cursor: "pointer",
              // scale: "1.1",
              // transition: "0.3s",
            },
          },
        }}
      >
        <Tab label="Vendor Codes - P1" style={{ padding: "10px" }} />
        <Tab label="Sub Category - P2" style={{ padding: "10px" }} />
        <Tab label="Category - P3" style={{ padding: "10px" }} />
        <Tab label="Collection Line - P4" style={{ padding: "10px" }} />
      </Tabs>

      <Paper elevation={3} style={{ padding: "20px", marginTop: "20px" }}>
        <TabPanel value={value} index={0}>
          <CommonGroupComponent
            masterField="vendorCode"
            customers={customers}
            isEditable={isEditable}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <CommonGroupComponent
            masterField="subCategory"
            customers={customers}
            isEditable={isEditable}
          />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <CommonGroupComponent
            masterField="category"
            customers={customers}
            isEditable={isEditable}
          />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <CommonGroupComponent
            masterField="collectionLine"
            customers={customers}
            isEditable={isEditable}
          />
        </TabPanel>
      </Paper>
    </MainSection>
  );
};

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
};

const CommonGroupComponent = ({ masterField, customers, isEditable }) => {
  const { control, getValues } = useFormContext();
  const values = getValues();
  const { fields } = useFieldArray({
    control,
    name: `frontEnd.${masterField}`,
  });

  return (
    <>
      {(fields || []).map((element, elementIndex) => {
        return element._id ? (
          <Box
            key={element.id}
            sx={{
              padding: "10px",
              marginBottom: "10px",
              backgroundColor: elementIndex % 2 === 0 ? "white" : "lightyellow",
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <pre
                  style={{
                    fontSize: "24px",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  {element?.name}
                </pre>
              </Grid>
              <Grid item xs={4}></Grid>
              <Grid item xs={2} style={{ textAlign: "center" }}>
                {isEditable ? (
                  <RHTextField
                    name={`${masterField}[${element._id}].laborPrice`}
                    label={"Labor Price"}
                    type={enums.dataTypes.number}
                  />
                ) : (
                  <Label
                    value={values[masterField]?.[element._id]?.laborPrice}
                    label="Labor Price"
                    labelStyle={{ fontWeight: "bold" }}
                  />
                )}
              </Grid>
              <Grid item xs={2} style={{ textAlign: "center" }}>
                {isEditable ? (
                  <RHTextField
                    name={`${masterField}[${element._id}].wastage`}
                    label={"Wastage %"}
                    type={enums.dataTypes.number}
                  />
                ) : (
                  <Label
                    value={values[masterField]?.[element._id]?.wastage}
                    label="Wastage %"
                    labelStyle={{ fontWeight: "bold" }}
                  />
                )}
              </Grid>

              <Grid item xs={12}>
                <DiscountGroup
                  customers={customers}
                  isEditable={isEditable}
                  discountGroupField={`${masterField}.${element._id}.discountGroup`}
                  totalPrice={values[masterField]?.[element._id]?.laborPrice}
                />
              </Grid>
            </Grid>
          </Box>
        ) : null;
      })}
    </>
  );
};

export default DetailContent;
