import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
  IconButton,
} from "@mui/material";
import { useFormContext, useFieldArray } from "react-hook-form";
import AddIcon from "@mui/icons-material/Add";
import MainSection from "@ui/MuiComponents/Section/MainSection";
import RHTextField from "@ui/ReactHookFormControls/RHTextField";
import Label from "@ui/components/Label";
import RHAutoComplete from "@ui/ReactHookFormControls/RHAutoComplete";
import RHCheckbox from "@ui/ReactHookFormControls/RHCheckbox";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import MyHoverIcons from "@ui/assets/commonIcons/customIcons/MyHoverIcons";
import { Tooltip } from "@material-ui/core/index";
import _ from "lodash";

const StoneLessConfig = ({ customers, configuration, isEditable }) => {
  const { control, getValues, setValue, watch } = useFormContext();
  const values = watch();
  const { fields, append, remove } = useFieldArray({
    control,
    name: `stoneLessConfigs`,
  });
  const [configWeightFields, setConfigWeightFields] = useState([]);

  useEffect(() => {
    setConfigWeightFields(
      (configuration.fields || []).filter(
        (field) => field.checked && field.weightField
      )
    );
  }, [configuration]);

  const handleAddRow = () => {
    append({
      slab: "",
      users: [],
      weightField: _.cloneDeep(configWeightFields).reduce((acc, field) => {
        acc[field.name] = {
          label: field.label,
          includeForLessWeight: field.includeForLessWeight || false,
          inputInCts: field.inputInCts || false,
        };
        return acc;
      }, {}),
    });
  };

  return (
    <MainSection title="Stone Less Config">
      <TableContainer component={Paper} sx={{ overflowX: "scroll" }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="right" style={{ maxWidth: "120px" }}>
                {isEditable ? (
                  <IconButton color="primary" onClick={handleAddRow}>
                    <Tooltip title="SLAB it">
                      <AddIcon />
                    </Tooltip>
                  </IconButton>
                ) : (
                  " "
                )}
              </TableCell>
              <TableCell style={{ minWidth: "160px" }}>Slab</TableCell>
              <TableCell style={{ minWidth: "300px" }}>Customers</TableCell>
              <TableCell>{isEditable ? "Select ALL" : ""}</TableCell>
              {configWeightFields.map((field) => (
                <TableCell key={field.name} style={{ minWidth: 120 }}>
                  {field.label + (field.inputInCts ? " {cts}" : " {g}")}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {fields.map((slab, slabIndex) => {
              return (
                <StoneFields
                  key={slab.id}
                  slab={slab}
                  slabIndex={slabIndex}
                  fields={fields}
                  isEditable={isEditable}
                  remove={remove}
                  getValues={getValues}
                  setValue={setValue}
                  customers={customers}
                  configWeightFields={configWeightFields}
                  watch={watch}
                />
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </MainSection>
  );
};

const StoneFields = ({
  slab,
  slabIndex,
  isEditable,
  remove,
  getValues,
  setValue,
  configWeightFields,
  customers,
  watch,
}) => {
  const [selectAll, setSelectAll] = useState(false);
  const stoneLessConfigs = watch(`stoneLessConfigs`);
  const weightField = watch(`stoneLessConfigs[${slabIndex}].weightField`);

  useEffect(() => {
    const allSelected = Object.values(weightField || {}).every(
      (field) => field.includeForLessWeight
    );

    setSelectAll(allSelected);
  }, [weightField]);

  return (
    <TableRow>
      <TableCell
        style={{
          textAlign: "center",
          // maxWidth: 50
        }}
      >
        {isEditable && slabIndex !== 0 ? (
          <MyHoverIcons
            muiIcon={
              <RemoveCircleIcon style={{ fontSize: "32px", color: "grey" }} />
            }
            muiIconHover={
              <RemoveCircleOutlineIcon
                style={{ fontSize: "44px", color: "black" }}
              />
            }
            onClick={() => remove(slabIndex)}
            tooltip={`REMOVE it`}
          />
        ) : null}
        {/* <b>{percentage || 0}%</b> */}
      </TableCell>
      <TableCell>
        {isEditable && slabIndex !== 0 ? (
          <RHTextField
            placeholder="Slab"
            name={`stoneLessConfigs[${slabIndex}].slab`}
          />
        ) : (
          <Label value={slab.slab} />
        )}
      </TableCell>
      <TableCell style={{ minWidth: "300px" }}>
        {isEditable && slabIndex !== 0 ? (
          <RHAutoComplete
            style={{ width: "300px" }}
            placeholder="Legends"
            options={customers.filter((customer) => {
              return !stoneLessConfigs.find(
                (originalSlab, originalSlabIndex) =>
                  originalSlabIndex !== slabIndex &&
                  (originalSlab.users || []).find(
                    (existingDiscountUser) =>
                      existingDiscountUser?._id === customer._id
                  )
              );
            })}
            name={`stoneLessConfigs[${slabIndex}].users`}
            getOptionLabel={(opt) =>
              opt.firstName + " " + opt.lastName + ` (${opt.phone})`
            }
            getOptionSelected={(opt, val) => opt._id === val._id}
            multiple={true}
          />
        ) : (
          <Label
            value={
              slabIndex !== 0
                ? (slab.users || [])
                    .map(
                      (user) =>
                        (user.firstName || "") +
                        " " +
                        (user.lastName || "") +
                        ` (${user.phone})`
                    )
                    .join(", ")
                : "All"
            }
          />
        )}
      </TableCell>
      <TableCell>
        {slabIndex !== 0 && (
          <Checkbox
            disabled={!isEditable}
            checked={selectAll}
            onChange={(event) => {
              const currentValues = getValues();
              setSelectAll(event.target.checked);

              if (event.target.checked) {
                Object.keys(
                  currentValues.stoneLessConfigs[slabIndex].weightField || {}
                ).map((weightFieldName) => {
                  currentValues.stoneLessConfigs[slabIndex].weightField[
                    weightFieldName
                  ].includeForLessWeight = true;
                });

                setValue(
                  `stoneLessConfigs[${slabIndex}].weightField`,
                  currentValues.stoneLessConfigs[slabIndex].weightField
                );
              } else {
                Object.keys(
                  currentValues.stoneLessConfigs[slabIndex].weightField || {}
                ).map((weightFieldName) => {
                  currentValues.stoneLessConfigs[slabIndex].weightField[
                    weightFieldName
                  ].includeForLessWeight = false;
                });

                setValue(
                  `stoneLessConfigs[${slabIndex}].weightField`,
                  currentValues.stoneLessConfigs[slabIndex].weightField
                );
              }
            }}
          />
        )}
      </TableCell>
      {configWeightFields.map((weightField) => (
        <TableCell key={weightField.name}>
          <RHCheckbox
            disabled={slabIndex === 0 || !isEditable}
            name={`stoneLessConfigs[${slabIndex}].weightField.${weightField.name}.includeForLessWeight`}
          />
        </TableCell>
      ))}
    </TableRow>
  );
};

export default StoneLessConfig;
